@import "../.././../public.scss";
.main {
  width: 278px;
  height: 70px;
  background: var(--btn-bg-color);
  border-radius: 89px;
  font-size: 20px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}
@media screen and  (max-width: $maxWidth){
.main {
  width: 126px;
  height: 54px;
  font-size: 16px;
}
}
