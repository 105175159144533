.header_main {
  padding: 0 22px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
   div.logo {
    display: flex;
     align-items: center;
  }
  p {
    font-family: SF-Pro-Text-Bold;
  }
  img {
    height: 40px;
    width: auto;
    border-radius: 10px;
    display: block;
    margin-right: 10px;
  }
  .row {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .row_pc {
    padding-right: 80px;

    > div {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
    ul {
      display: flex;
      justify-content: space-around;
      width: 100%;
      font-size: 22px;
      li{
        font-family: SF-Pro-Text-Semibold;
        cursor: pointer;
        a{
          @extend li;
          color: white;
        }
      }
    }
    a {
      color: white;
      font-size: 16px;
    }
  }
}
.Dropdown_header {
 :global {
   .ant-dropdown-menu {
     background-color: #212121;
     border-radius: 8px;
     border: 2px solid rgba(255,255,255,0.5);
     li.ant-dropdown-menu-item  {
       color: white;
       font-size: 22px;
       line-height: 26px;
     }
   }
 }

}
