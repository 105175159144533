.main {
  background-color: #000000;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100vw);
  height: 100vh;
  z-index: 1000;
  touch-action: none;
  font-family: SF-Pro-Text-Semibold;
  font-size: 14px;
  z-index: 90;
  padding: 0 22px;
  box-sizing: border-box;
  header {
    padding-top: 30px;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        @extend li;
        color: white;
        margin: 0;
      }
    }

  }
}
