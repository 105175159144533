$maxWidth:992px;
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s
}


.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    cursor: pointer;
    font-size: 24px;
  }

  img {
    width: 45px;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
  }
}
div.Policy{
  color: black;
  padding: 1rem;
  margin: 0 auto;
  line-height: 40px;
  font-size: 18px;
}
.April{
  font-size: 14px;
  color: black;
}
div.Agreement{
  color: black;
  padding: 1rem;
  margin: 0 auto;
  line-height: 40px;
  font-size: 18px;
}
