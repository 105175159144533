.mian_plans {
 display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 18px;
  .box_item{
    margin-bottom: 16px;
    padding-top: 54px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 460px;
    border-radius: 15px;
    img {
      width: 100%;
      position: absolute;
      height: auto;
      left: 0;
      top: 0;
    }
    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      text-align: center;
      color: var(--card-color);
      h2 {
        margin-bottom: 22px;
      }
      p {
        margin-bottom: 80px;
      }
      div.text {
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        background: rgba(255,255,255,0.57);
        border-radius: 19px 19px 19px 19px;
        position: relative;
        top: -50px;
      }
      header {
        margin-bottom: 11px;
      }
      .bnt {
        width: 151px;
        height: 56px;
        background: var(--text-color);
        border-radius: 51px 51px 51px 51px;
        color: var(--card-color);
        font-weight: bold;
      }
    }
  }

}
