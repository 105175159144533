@import "../../../public.scss";
.main_JoinTheCommunity {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  touch-action: none;
  .container {
    width: 600px;
    transform: scale(.8);
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;
    padding: 32px 16px;
    box-sizing: border-box;
    position: relative;
    touch-action: none;
    h3 {
      text-align: center;
      font-size: 28px;
      margin: 0;
      margin-bottom: 20px;
    }
    .text {
      display: flex;
      color: #000000;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 10px;

      img {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 9px;
      }
    }
    .text:last-child {
      margin-bottom: 30px;
    }
    .bnt {
      margin-bottom: 10px;
      position: relative;
      background: #0EA5E9 ;
      width: 100%;

      > div {
         display: flex;
         align-items: center;
         width: 140px;
        white-space: nowrap;
         img {
          margin-right: 14px;
          width: 30px;
        }
      }
      .hot, .new {
        font-size: 16px;
        position: absolute;
        top: 20px;
        right: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
      .hot {
        width: 52px;
        height: 28px;
        background: linear-gradient(93deg, #FF3939 0%, #FF9534 100%);
        border-radius: 10px 10px 10px 10px;
        color: white;
      }
      .new {
        width: 52px;
        height: 28px;
        background: linear-gradient(93deg, #ECFF0E 0%, #61FF39 100%);
        border-radius: 10px 10px 10px 10px;
        color: #000000;
      }
    }
    .closeUrl {
      position: absolute;
      right: 10px;
      transform: scale(.8);
      top: 10px;
      cursor: pointer;
    }

  }
  .container_show {
    width: 600px;
    transform: scale(1);
    align-items: flex-start;
    padding-top: 0;
  }
}
.main_JoinTheCommunity_box {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  touch-action: none;
}

@media screen and (max-width: $maxWidth){
  .main_JoinTheCommunity {
    .container {
      width: 688px;
      h3 {
        text-align: center;
        font-family:SF-Pro-Text-Bold ;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 20px;
      }
      .text {
        display: flex;
        color: #000000;
        font-size:16px;
        line-height: 24px;
        margin-bottom: 10px;
        font-weight: bold;

        img {
          width: 24px;
          height: 24px;
          display: block;
          margin-right: 9px;
        }
      }
      .bnt {
        height: 46px;
        background: #0EA5E9;
        .hot, .new {
          font-size: 16px;
          position: absolute;
          top: 10px;
          right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

        }
      }
    }
  }
}

