.main {
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.09);

  .main_item {
    .header {
      height: 80px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      font-size: 18px;
      cursor: pointer;
      justify-content: space-between;

      img {
        width: 14px;
      }
    }

    .content {
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      padding: 30px 0;
      padding-left: 20px;
      color: white;
      font-size: 14px;
      display: none;
      opacity: 0;
      transition: all 800ms ease-in-out;
    }

    .active {
      display: block;
      opacity: 1;

    }
  }
}
