@import "../../public.scss";
.home_main {
  color: var(--text-color);
  overflow: hidden;
 ._title {
   text-align: center;
   font-size: 68px;
   font-weight: bold;
 }
  .sub__title {
    font-size: 24px;
    text-align: center;
    color: var(--text-color);
    padding: 20px 0 30px;
  }
  .buttonWarp {
    display: flex;
    justify-content: center;
  }
  .account_title {
    color: var(--text-color);
    text-align: center;
    padding: 20px 0 10px;
    font-size: 18px;

  }
  .login_text {
    font-size: 14px;
    text-align: center;
    a{
      cursor: pointer;
      color: var(--btn-bg-color);
      font-family: SF-Pro-Text-Bold;
    }
  }
  .img1 {
    width: 80%;
    max-width: 104vw;
    display: block;
    margin: 40px auto;
  }
  .home_card_warp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .home_card {
      min-width: 340px;
      height: 140px;
      background: var(--card-bg-color);
      color: var(--card-color);
      border-radius: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      padding: 10px;
      box-sizing: border-box;
      .text {
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        margin-right: 40px;
        p:last-child {
          font-size: 16px;
          font-weight: normal;
          padding-top: 10px;
        }
      }
      img {
        width: 80px;
      }
    }
  }
  .card_box {
    background-color: var(--h-card-color);
    padding: 30px 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 68px;
    }
    .sub_title {
      @extend .sub__title;
    }
    .note_text {
      font-size: 24px;
      padding-bottom: 40px;
    }
    .img2 {
      display: block;
      width: 900px;
      margin: 60px auto 40px;
      max-width: 100%;
    }

  }
  .card_box_works {
    background-color: transparent;
    .img2 {
      margin-top: 0px;
    }
  }
  .card_box_plans {
    background-color: var(--h-card-color1);
    .plans_item_warp {
      margin: 0 auto;
      width: 1200px;
      padding-top: 60px;
      color: var(--card-color);
      margin-bottom: 40px;
    }
    .plans_item:hover {
      border-color: #B65EFD;
    }
    .plans_item {
      background: #FFFFFF;
      width: 100%;
      min-height: 384px;
      border-radius: 8px;
      border: 4px solid transparent;
      padding: 30px 20px 20px;
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
       div , p, h4 {
         line-height: 40px;
       }
      div.popular {
        color: var(--btn-bg-color);
        padding: 6px 14px;
        border-radius: 6px;
        line-height: normal;
        background-color: var(--top-bg-color);
        transform: rotate(-10deg);
        position: relative;
        top: -10px;
      }
      ._bnt {
        width: 100%;
        height: 56px;
        border-radius: 7px;
      }

    }
  }
  .card_box_confidential {
    background-color: transparent;
  }
  .card_box_faq {
    .card_box_faq_warp {
      width: 1200px;
      margin: 0 auto;
      max-width: 90vw;
    }
    h2 {
      text-align: left;
    }
  }
  .footer_box {
    margin: 0 auto 40px;
    max-width: 100vw;
    position: relative;
    text-align: center;
    color: var(--card-color);
    img {
      width: 100%;
      height: auto;
    }
    .footer_box_content {
      position: absolute;
      left: 50%;
      max-width: 100%;
      height: 100%;
      transform: translateX(-50%);
      width: 1200px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //padding-top: 100px;
      p{
        font-size: 18px;
      }
      h2 {
        padding: 20px 0  20px 0;
      }
      .txt {
        margin-bottom: 20px;
        margin-top: 20px;
        width: 199px;
        height: 37px;
        background: rgba(255,255,255,0.63);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 26px;
      }
      .footer_box_bnt {
        background-color: var(--text-color);
        color: var(--card-color);
        width: 209px;
        margin-top: 40px;
      }
    }
  }
  footer {
    padding: 50px 10px;
    color: #C4C4C4;
    //margin-bottom: 40px;
    text-align: center;
    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      a {
        padding: 0 20px;
        text-decoration: underline;
        cursor: pointer;
        color: #C4C4C4;
        white-space: nowrap;
      }
    }
    .icons {
      margin-bottom: 40px;
      img {
        width: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: $maxWidth){
.home_main {
  .img1 {
    width: 106%;
    position: relative;
    left: -10px;
  }
  ._title {
    padding: 0 40px;
    font-size: 34px;
  }
  .login_text {
    a {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .sub__title {
    padding: 20px 35px;
    font-size: 16px;
  }
  .home_card_warp {
    justify-content: flex-start;
    padding-left: 13px;
    max-width: 100vw;
    overflow: auto hidden;
    -webkit-overflow-scrolling: touch;
    .home_card {
      min-width: 240px;
      height: 106px;
      .text {
       font-size: 22px;
        margin-right: 10px;
        font-weight: bold;
        p:last-child{
          font-size: 14px;
        }
      }
      img {
        width: 48px;
      }
    }
  }
  .home_card_warp::-webkit-scrollbar{
    width: 0 !important;
    height: 0;
  }
  .card_box{
    //.sub_title {
    //  color: white;
    //}
    h2 {
      font-size: 36px;
      font-family: SF-Pro-Text-Bold;
    }
  }
  .card_box_works {
    margin-bottom: 0;
    .img2{
      margin-bottom: 0;
    }
    .buttonWarp_bnt {
      width: 126px;
      height: 54px;
      margin-bottom: 26px;
    }
  }
  .card_box1 {
    .note_text {
      text-align: left;
      font-size: 16px;
    }
  }
  .card_box_confidential {

  }
}
}
