.mian_plans {
  padding: 0 16px;
  color: var(--card-color);
  background:#101010;
  padding-bottom: 29px;
  h2 {
    text-align: center;
    padding-bottom: 30px;
    color: white;
    font-size: 48px;
    padding-top: 50px;
  }
  .card_box {
    min-height: 366px;
    background: linear-gradient(to right, #B65EFD, #B65EFD);
    border-radius:14px;
    padding: 24px 18px 11px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
     width: 100%;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    flex-direction: column;
    margin-bottom: 19px;
    header {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      p {
        font-size: 14px;
        min-width: 78px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 28px;
        border-radius:  22px;
        border: 1px solid #000000;
        line-height: 28px;
      }
      span {
        display: flex;
        align-items: center;
        i {
          position: relative;
          display: block;
          color: var(--text-color);
          img {
            width: 38px;
          }
          b{
            font-weight: normal;
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-8px, -10px);
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 34px;
      background: var(--text-color);
      border-radius: 12px;
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        display: block;
        width: 35px;
        height: 34px;
        border-radius:0 34px 34px 0;
        margin-right: 10px;
        background-color: #E0E4D5;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
        }
      }
    }
    .bnt {
      width: 100%;
      height: 46px;
      background: var(--card-color);
      border-radius: 24px;

    }

  }
  .card_box1 {
    @extend .card_box;
  }
  .card_box2 {
    @extend .card_box;
    background: linear-gradient(148deg, #B65EFD 0%, #41D1FF 100%);
  }
}
