$maxWidth:992px;
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s
}

