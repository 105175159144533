
:root {
  --main-bg-color: #212121;
  --text-color: #fff;
  --btn-bg-color: #B65EFD;
  --card-bg-color:#F9E714;
  --card-color: #000;
  --h-card-color: #2F2F2F;
  --h-card-color1: #101010;
  --top-bg-color:#FCDE40;
}
body, html, * {
  margin: 0;
  margin: 0;
  list-style: none;
  /*user-select: none;*/
  line-height: normal;
  font-family: Pro-Text-Regular;
}
body{
  min-height: 100vh;
}
h2 {
  font-family: SF-Pro-Text-Heavy;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
  height: 10px;
}
